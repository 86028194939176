<span class="info" ><img src="../../assets/img/t3.png" class="img-info" alt="">
    <span class="tooltip-data">
        <ul>
            <li>{{teamData[0]}} (c)</li>
            <li>{{teamData[1]}}</li>
            <li>{{teamData[2]}}</li>
            <li>{{teamData[3]}}</li>
          </ul>
    </span>
</span>
