import { HttpClient, HttpClientModule, HttpHeaders } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { Observable } from 'rxjs';
import { DropdownModel } from './dropdown.model';
import { MatTooltipModule } from '@angular/material/tooltip';


@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})

export class AppComponent implements OnInit {
  title = 'fit4life';

  calculatorForm: UntypedFormGroup;

  public summerChallengeData;
  public summerChallengeTeamsData;
  public teamDetails: DropdownModel[] = [];
  public scoreMatrixData;
  public currentWeek: string = '';
  public errorText: string = '';
  public table_1 = []
  public table_2 = []
  public is_form_disabled = true;
  // Update the default value to 0
  public progress_value = '0%'
  public total_distance = 0
  public total_distance_earth = 0;
  public diff_in_days_toady
  public difference_In_Days
  public submit_data_list = []


  constructor(private httpClient: HttpClient, private fb: UntypedFormBuilder) {

    this.fetchTeamsDetail().subscribe((res) => {
      let total = 0
      this.summerChallengeData = res;
      this.summerChallengeTeamsData = res && res['data'];
      this.is_form_disabled = res['is_form_disabled'];
      this.summerChallengeTeamsData.forEach(team => {
        if(team['show_list']) {
          let teamDetail: DropdownModel = new DropdownModel();
          teamDetail.id = team.id;
          teamDetail.value = team.name;
          this.teamDetails.push(teamDetail);
        }
        else {
          this.submit_data_list.push(team)
        }

        // Sort teamDetails alphabetically
        if(this.teamDetails.length > 1) {
          this.teamDetails.sort((a, b) => a.value.localeCompare(b.value));
        }
        // if(this.submit_data_list.length > 1) {
        //   this.submit_data_list.sort((a, b) => a.value.localeCompare(b.value));
        // }  

        // console.log('total team', team['name'], team['total_point'] )

        // total += team['total_point']
        // console.log('total team', team['name'], team['total_point'] )

      });
    this.claculate_progress()
      if(this.is_form_disabled) {
        this.leaderboardData(res['data'])

      }
      this.currentWeek = 'Week ' + String(res.current_week);
    });
    // UPDATE THE BELOW DATES
    let date1 = new Date("7/01/2024");
    let date2 = new Date("8/31/2024");
    let today = new Date()
    let difference_In_Time = date2.getTime() - date1.getTime()
    let diff_toady = today.getTime() - date1.getTime()
    this.diff_in_days_toady = Math.round(diff_toady / (1000 * 3600 * 24))
    this.difference_In_Days = difference_In_Time / (1000 * 3600 * 24)




    this.getScoreMatrix().subscribe((res) => {
      this.scoreMatrixData = res && res['score_matrix'];
    });

  }

  claculate_progress() {
    let total = 0
    this.summerChallengeTeamsData.forEach(e => {
      total += Math.round(e['total_point'])
      // console.log('total', total)
    });
    // Uncomment below three lines
    this.total_distance = Math.round(total/7.93)
    this.total_distance_earth = this.total_distance > 50514 ? this.total_distance - 50514 : this.total_distance;
    const p = this.total_distance > 50514 ? Math.round((this.total_distance_earth/40075)*100) : Math.round((this.total_distance/50514)*100);
    this.progress_value = `${p}%`
  }

  ngOnInit() {
    this.buildForm();
  }

  buildForm = (): void => {
    this.calculatorForm = this.fb.group({
      teamName: [''],
      walking: [''],
      running: [''],
      cycling: [''],
      yoga: [''],
      swimming: [''],
      team_sport: [''],
      individual_sport: [''],
      gym: [''],
      teamPin: [''],
      totalPoints: [0]
    });
  }

  getPoints = (ctrlName: string): string => {
    let totalPoint: number = this.getPointPerWorkout(ctrlName);
    return totalPoint ? totalPoint.toString() + ' pts' : '-';
  }

  getPointPerWorkout = (ctrlName: string): number => {
    let totalPoint: number = 0;
    let inputVal: number = this.calculatorForm.value[ctrlName];
    let pointsPerUnit: number = this.getPointsPerUnit(ctrlName);
    totalPoint = inputVal && inputVal > 0 ? inputVal * pointsPerUnit : 0;
    return totalPoint ? Number(totalPoint.toFixed(2)) : 0;
  }

  getPointsPerUnit = (ctrlName: string) => {
    return this.scoreMatrixData && this.scoreMatrixData[ctrlName] ? this.scoreMatrixData[ctrlName].point : 0;
  }

  getScoreMatrix = (): Observable<any> => {
    return this.httpClient.get<any>('https://jgwxhydted.execute-api.eu-west-2.amazonaws.com/summer-challenge-data/score-matrix');
  }

  fetchTeamsDetail = (): Observable<any> => {
    return this.httpClient.get<any>('https://jgwxhydted.execute-api.eu-west-2.amazonaws.com/summer-challenge-data');
  }

  getTotalPoints = (): string => {
    let teamTotalPoints: number = this.calculateTotalPoints();
    return teamTotalPoints ? teamTotalPoints.toFixed(2) + 'pts' : '0 pts';
  }

  calculateTotalPoints = (): number => {
    let totalPoints: number = 0;
    Object.keys(this.calculatorForm.value).forEach(ctrlName => {
      if (ctrlName !== 'teamName' && this.calculatorForm.value[ctrlName] > 0) {
        totalPoints += this.getPointPerWorkout(ctrlName);
      }
    });
    return totalPoints;
  }

  saveDetails = (): void => {
    if(!this.is_form_disabled) {
      let enteredPin = this.calculatorForm.value['teamPin'] && this.calculatorForm.value['teamPin'] !== '' ?
      this.calculatorForm.value['teamPin'] : null;
      

      let teamName: string = this.calculatorForm.value['teamName'] && this.calculatorForm.value['teamName'] !== ''
      && this.calculatorForm.value['teamName'] !== 'Please Select' ? this.calculatorForm.value['teamName'] : null

      let totalPoint: string = this.calculatorForm.value['totalPoints'] && this.calculatorForm.value['totalPoints'] !== ''
       ? this.calculatorForm.value['totalPoints'] : null
      if(teamName) {
        this.errorText = '';
        if(enteredPin) {
          this.errorText = '';
          if(this.isPinValid(enteredPin)) {
            this.errorText = '';
            if(totalPoint) {
              this.errorText = '';
              this.saveTeamDetails();
            }
            else {
              this.errorText = 'Please enter valid Points';
            }
            
          } else {
            this.errorText = 'Enter Valid Pin';
          }
        } else {
          this.errorText = 'Please enter Team Security pin!!';
        }
      } else {
        this.errorText = 'Please select Team First!!';
      }
  }
  }

  isPinValid = (enteredPin): boolean => {
    let selectedTeamData = this.getSelectedTeamData() ? this.getSelectedTeamData() : null;
    let validPin = selectedTeamData.pin;
    if (validPin == enteredPin) {
      return true;
    } else {
      return false;
    }

  }

  saveTeamDetails = (): void => {
    this.errorText = "";
    this.preparePayload();
    // console.log('backendPayload-------------',backendPayload)

  }


  saveDetailsToBackend(payload) {
    let urlToHit: string = 'https://jgwxhydted.execute-api.eu-west-2.amazonaws.com/summer-challenge-data';
    return this.httpClient.put(urlToHit, payload);
  }


  getSelectedTeamData = (): any => {
    let selectedTeam: string = this.calculatorForm.value['teamName'] && this.calculatorForm.value['teamName'] !== ''
    && this.calculatorForm.value['teamName'] !== 'Please Select' ? this.calculatorForm.value['teamName'] : null

      if(selectedTeam) {
        let selectedTeamData = this.summerChallengeTeamsData.filter(team => {
          return team.name === selectedTeam;
        });
        return selectedTeamData[0];
      }

    return null;
  }

  preparePayload = () : any => {

    this.fetchTeamsDetail().subscribe((res) => {
      this.summerChallengeData = res;
      let summerChallengeDetails = this.summerChallengeData;
      let selectedTeamData = this.getSelectedTeamData() ? this.getSelectedTeamData() : null;
      let teamIndexToUpdate = summerChallengeDetails.data.findIndex(team => team.id === selectedTeamData.id);
      // selectedTeamData['total_point'] += this.calculateTotalPoints();
      selectedTeamData['total_point'] += Math.round(this.calculatorForm.value['totalPoints'])
      selectedTeamData['show_list'] = false
      const week_data = {
        date: new Date(),
        points: this.calculatorForm.value['totalPoints']
      }
      selectedTeamData['week'].push(week_data)
      summerChallengeDetails.data[teamIndexToUpdate] = selectedTeamData;
      summerChallengeDetails.data = Object.assign([], summerChallengeDetails.data);
      this.saveDetailsToBackend(summerChallengeDetails).subscribe(data => {
        this.errorText = "";
        this.summerChallengeData = data;
        this.summerChallengeTeamsData.forEach(team => {
          if(team['show_list']) {
            let teamDetail: DropdownModel = new DropdownModel();
            teamDetail.id = team.id;
            teamDetail.value = team.name;
            this.teamDetails.push(teamDetail);
          }
          else {
            this.submit_data_list.push(team)
          }
        })

        // Sort teamDetails alphabetically
        if(this.teamDetails.length > 1) {
          this.teamDetails.sort((a, b) => a.value.localeCompare(b.value));
        }
        // if(this.submit_data_list.length > 1) {
        //   this.submit_data_list.sort((a, b) => a.value.localeCompare(b.value));
        // }        

        alert("Your details updated successfully!!")
        // this.is_form_disabled = true;
        // this.onclickLeaderBoard()

        location.reload()
      },
        err => {
          this.errorText = "Technical Error";
        });
    })

  }

  onclickLeaderBoard() {
    document.getElementById("leaderboard").scrollIntoView();
  }

  onclickLogPoint() {
    document.getElementById("logPoint").scrollIntoView();
  }

  onclickCalculator() {
    document.getElementById("calculator").scrollIntoView();
  }

  onclickFinalTarget() {
    document.getElementById("finalTarget").scrollIntoView();
  }

  onclickContactt() {
    document.getElementById("contact").scrollIntoView();
  }

  leaderboardData(data) {
    data = data.sort((a, b) => b.total_point - a.total_point);
    const len = data.length
    const index = (data.length % 2 == 0)? len/2 : (len + 1) / 2
    this.table_1 = data.splice(0, index);
    this.table_2 = data
  }


  p_value() {
    return `width: ${this.progress_value}`
  }

}


