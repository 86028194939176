<header id="header" class="header fixed-top">
  <div class="container-fluid container-xl d-flex align-items-center justify-content-between">

    <a href="index.html" class="logo d-flex align-items-center">
      <img src="../assets/img/tcs1.png" alt="" style="margin-right: 1rem;">
      <img class="img-1 landg-logo"
        src="https://cms.legalandgeneral.com/globalassets/global-shared-assets/logos/landg-logo.svg" alt="">
    </a>

    <nav id="navbar" class="navbar">
      <ul>
        <li><a class="nav-link scrollto active pointer" href="#">Home</a></li>
        <li><a class="nav-link scrollto pointer" (click)="onclickFinalTarget()">Final Target</a></li>
        <li><a class="nav-link scrollto pointer" (click)="onclickLeaderBoard()">Leaderboard</a></li>
        <li><a class="nav-link scrollto pointer" (click)="onclickCalculator()">Calculator</a></li>
        <li><a class="nav-link scrollto pointer" (click)="onclickLogPoint()">Log Points</a></li>
        <li><a class="nav-link scrollto pointer" (click)="onclickContactt()">Contact us</a></li>
      </ul>
      <i class="bi bi-list mobile-nav-toggle"></i>
    </nav>

  </div>
</header>

<section id="hero" class=" sectionField hero d-flex align-items-center">

  <div class="container">
    <div class="row" style="margin-top: 9rem;">
      <div class="col-lg-6 d-flex flex-column justify-content-center">
        <h1>Summer Fitness Challenge 2K24</h1>
        <!-- <h2>Get Set for Summer, Time to don your Running/Walking gear, Swimming, Cycling, Gym, Yoga¸ Team Sports, Individual sports & a lot more.</h2> -->
        <p class="mt-3"><i>
            Starts from <b>July 1st</b> & ends on the <b>August 31st</b>.</i></p>
        <p>Let’s shed some Calories and Body fat. All progress takes place outside the comfort zone. The pain you feel
          today, will be the strength you feel tomorrow.</p>
        <p><b>Please register your team TODAY, if not already done.</b></p>


        <div>
          <div class=" text-lg-start">
            <!-- <p>Please register your teams TODAY, if not already done.</p> -->
            <!-- <a href="https://forms.office.com/e/GkN2BrNT9Y" target="_blank"  class="btn-get-started scrollto d-inline-flex align-items-center justify-content-center align-self-center">
                  <span>Register here</span>
                  <i class="bi bi-arrow-right"></i>
                </a> -->
            <!-- <a  class="btn-get-started ml-1 scrollto d-inline-flex align-items-center justify-content-center align-self-center">
                  <span>Leader Board</span>
                  <i class="bi bi-arrow-down"></i>
                </a>
                <a  class="btn-get-started ml-1 scrollto d-inline-flex align-items-center justify-content-center align-self-center">
                  <span>Calculator</span>
                  <i class="bi bi-arrow-down"></i>
                </a> -->
          </div>

        </div>
      </div>
      <div class="col-lg-6 hero-img">
        <img src="../assets/img/f2.png" style="border-radius: 5px;" class="img-fluid" alt="">


      </div>
      <div class="col-md-12 text-center mt-5">
        <img class="img-2" src="../assets/img/tcs1.png" alt=""> &nbsp; &nbsp;
        <img class="img-1" src="https://www.legalandgeneral.com/landg-assets/global-shared-assets/logos/landg-logo.svg"
          alt="">

        <h2 style="color: #4154f1;"><b>Celebrating 15 years of partnership</b> </h2>
      </div>
    </div>
  </div>

</section><!-- End Hero -->

<main id="main">

  <section id="finalTarget" class="values counts">

    <div class="container">

      <header class="section-header" style="padding-bottom: 0;">
        <h2>Our Values</h2>
        @if (total_distance < 50514) {
          <p>Final Target: Explore Global Wonders</p>
        } @else {
          <p>Final Target: Around The World</p>
        }
        
        <!-- <p style="font-size: 1.2rem;">Conquer the Distance from Earth to Moon </p> -->
        
        @if (total_distance < 50514) {
          <p style="font-size: 1.2rem;">"CONQUER THE JOURNEY FROM LONDON TO THE WONDERS OF THE WORLD"</p>
          <p style="font-size: 1.2rem;">313,87 miles (around 50,514 kilometers)</p>
        } @else {
          <p style="font-size: 1.2rem;">"CONQUER THE DISTANCE OF EARTH'S CIRCUMFERENCE"</p>
          <p style="font-size: 1.2rem;">24,901 miles (around 40,075 kilometers)</p>
        }

      </header>

      <div class="row p-2">
        <div class="col-md-2 m-auto count-box">
          <p class="p-2">Total distance covered(km): <span>{{total_distance_earth}}</span></p>
        </div>
        <div class="col-md-7 m-auto bg-white" style="background-color: white;">
          @if (total_distance < 50514) {
            <div class="d-flex align-items-center">
              <!-- <img src="../assets/img/walk-earth.gif" class="img-fluid m-auto" style="max-width: 80%;" alt=""> -->
              <!-- <div style="width:100%;height:0;padding-bottom:100%;position:relative;"> -->
              <!-- <img src="../assets/img/rocket2.gif" class="img-fluid m-auto" style="max-width: 80%;" alt=""> -->
  
  
              <img src="../assets/img/London.jpg" class="img-fluid m-auto zoom hover3d rounded-shadow"
                matTooltip="London: The starting point of our Summer Challenge journey. Known as the capital city of England, London is where our adventure begins."
                style="max-width: 11.11%;" alt="">
              <img src="../assets/img/Colloseum.jpg" class="img-fluid m-auto zoom hover3d rounded-shadow"
                matTooltip="Colosseum, Italy: An iconic symbol of Imperial Rome, it's the largest amphitheater ever built. Located 1836km from London, it's our next stop in the Summer Challenge journey."
                style="max-width: 11.11%;" alt="">
              <img src="../assets/img/Giza.jpg" class="img-fluid m-auto zoom hover3d rounded-shadow" matTooltip="Great Pyramid of Giza, Egypt: The oldest of the Seven Wonders of the Ancient World and the only one to remain largely intact. 
                It's located 3518km from the Colosseum, marking our next stop in the Summer Challenge journey."
                style="max-width: 11.11%;" alt="Great Pyramid of Giza">
              <img src="../assets/img/Jordan.jpg" class="img-fluid m-auto zoom hover3d rounded-shadow"
                matTooltip="Petra, Jordan: Located 1170km from the Great Pyramid of Giza, A famous archaeological site in Jordan's southwestern desert."
                style="max-width: 11.11%;" alt="">
              <img src="../assets/img/Taj.jpg" class="img-fluid m-auto zoom hover3d rounded-shadow"
                matTooltip="Taj Mahal, India: The Taj Mahal is an ivory-white marble mausoleum on the right bank of the Yamuna river in the Indian city of Agra. Located 4290km from Petra."
                style="max-width: 11.11%;" alt="">
              <img src="../assets/img/China.jpg" class="img-fluid m-auto zoom hover3d rounded-shadow"
                matTooltip="A series of fortifications made of stone, brick, tamped earth, wood, and other materials, generally built to protect the Chinese states and empires against the raids and invasions of the various nomadic groups of the Eurasian Steppe. Located 3700km from the Taj Mahal."
                style="max-width: 11.11%;" alt="">
              <img src="../assets/img/Chichen.jpg" class="img-fluid m-auto zoom hover3d rounded-shadow"
                matTooltip="Chichén Itzá, Mexico: A large pre-Columbian city built by the Maya people of the Terminal Classic period. Located 13,000km from the Great Wall of China."
                style="max-width: 11.11%;" alt="">
              <img src="../assets/img/Rio.jpg" class="img-fluid m-auto zoom hover3d rounded-shadow"
                matTooltip="Christ the Redeemer, Rio: An Art Deco statue of Jesus Christ in Rio de Janeiro, Brazil, created by French sculptor Paul Landowski and built by Brazilian engineer Heitor da Silva Costa, in collaboration with French engineer Albert Caquot. Located 10,000km from Chichén Itzá."
                style="max-width: 11.11%;" alt="">
              <img src="../assets/img/Machu pichu.jpg" class="img-fluid m-auto zoom hover3d rounded-shadow"
                matTooltip="Machu Picchu, Peru: Located 3,000km from Christ the Redeemer, an Incan citadel set high in the Andes Mountains in Peru, above the Urubamba River valley.
                From here, we head back to our ending point, London, which is approximately 10,000km away."
                style="max-width: 11.11%;" alt="">
              <!-- <img src="../assets/img/rocket.webp" class="img-fluid m-auto" style="max-width: 80%;" alt=""> -->
              <!-- <img src="../assets/img/Moon.PNG" class="img-fluid m-auto" style="max-width: 80%;" alt=""> -->
              <!-- </div> -->
            </div>
          } @else{
            <div class="d-flex align-items-center">
              <img src="../assets/img/walk-earth.gif" class="img-fluid m-auto" style="max-width: 80%;" alt="">
            </div>
          }
          <div class="progress">
            <div class="progress-bar progress-bar-striped progress-bar-animated" role="progressbar"
              aria-valuenow="p_value()" aria-valuemin="0" aria-valuemax="100" [ngStyle]="{'width': progress_value }">
              <b>{{progress_value}}</b>
            </div>
          </div>
          <div>

          </div>
        </div>
        <div class="col-md-2 m-auto count-box">
          <!-- Uncomment Below Lines-->
          <p class="p-2">Number of days completed: <span>{{diff_in_days_toady}}/{{difference_In_Days}}</span></p>
          <!-- <p class="p-2">Number of days completed: <span>0/62</span></p> -->
        </div>

      </div>

      <div class="section-header">
        <p><img src="../assets/img/lock.gif" style="width: 25%" class="img-fluid m-auto"></p>
        @if (total_distance < 50514) {
          <p style="font-size: 1.2rem;">Finish this quest to unlock the "AROUND THE WORLD" challenge</p>
        } @else {
          <p style="font-size: 1.2rem;">Congratulations!!! We've unlocked the "AROUND THE WORLD" challenge</p>
        }

      </div>


    </div>

  </section>

  <section id="leaderboard" class="values">

    <div class="container" style="max-width: 100%;">
      <div class="aaa d-flex flex-column justify-content-center w-100 h-100" style="padding: 5rem; border-radius: 4px;">
        <header class="section-header">
          <p>Leaderboard</p>
        </header>
        <div class="row text-white" *ngIf="!is_form_disabled">
          <!-- Uncomment Below Lines-->
          <h4 class="text-white">Leaderboard will be visible once the Log points form will close for the {{currentWeek}}
          </h4>
          <div style="margin-bottom: 1rem;"></div>
          <h5> List of teams submitted the points</h5>
          <ul class="ml-3">
            <li *ngFor="let data of submit_data_list">
              {{data['name']}}
            </li>
          </ul>
        </div>
        <!-- Comment After Summer challenge Start -->
        <!-- <div class="row " *ngIf="is_form_disabled">
              <h4 class="text-white">Leaderboard will be visible once the summer challenge begins...</h4>
            </div> -->
        <div class="row " *ngIf="is_form_disabled">
          <div class="col-md-6">
            <table class="table table-striped table-hover" style="border-radius: 4px;">
              <thead class="thead-dark">
                <tr>
                  <th scope="col">Position</th>
                  <th scope="col">Team Name</th>
                  <th scope="col">Total Point</th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let data of table_1; let i = index" class="tr">
                  <th scope="row">{{i+1}}</th>
                  <td>{{data['name']}} <app-tooltip [teamData]="data['team']"></app-tooltip>
                    <span *ngIf="i==0"><img src="../assets/img/gold.png" style="width: 2rem;"></span>
                    <span *ngIf="i==1"><img src="../assets/img/silver.png" style="width: 2rem;"></span>
                    <span *ngIf="i==2"><img src="../assets/img/bronze.png" style="width: 2rem;"></span>
                  </td>
                  <td>{{data['total_point']}}</td>
                </tr>
              </tbody>
            </table>
          </div>
          <div class="col-md-6">
            <table class="table table-striped table-hover" style="border-radius: 4px;">
              <thead class="thead-dark">
                <tr>
                  <th scope="col">Position</th>
                  <th scope="col">Team Name</th>
                  <th scope="col">Total Point</th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let data of table_2; let i = index">
                  <th scope="row">{{table_1.length + i + 1}}</th>
                  <td>{{data['name']}}
                    <app-tooltip [teamData]="data['team']"></app-tooltip>
                  </td>
                  <td>{{data['total_point']}}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>

      </div>

    </div>

  </section>
  <section id="calculator" class="about">

    <div class="container">
      <div class="row gx-0">

        <div class="col-lg-12 d-flex flex-column justify-content-center">
          <div class="content">

            <form class="mt-10" [formGroup]="calculatorForm">
              <!-- <div class="row">
                    <div class="col-md-4">
                      <div *ngIf="errorText && errorText !== ''" class="error-msg mt-10">{{errorText}}</div>
                    </div>
                  </div>
                <div class="row">

                  <div class="col-md-3">
                    <label for="teamName" class="form-label">Select Team</label>
                    <select formControlName="teamName" class="form-select" id="teamName" required *ngIf="teamDetails.length > 0">
                      <option style="display: none;" selected>Please Select</option>
                      <option *ngFor="let team of teamDetails" class="bi bi-chevron-down">
                        {{team['value']}}
                      </option>
                    </select>
                    <div class="invalid-feedback">
                      Please provide a valid team name.
                    </div>
                  </div>
                  <div class="col-md-2">
                    <div>
                      <label for="teamPin" class="form-label">Security Pin</label>
                      <input type="password" class="form-control" maxlength="4" placeholder="Enter 4 digit pin" id="teamPin" formControlName="teamPin"/>  
                    </div>

                  </div>
                  <div class="col-md-2">
                    <div>
                      <label for="ttalPoints" class="form-label" style="color: #4154f1;"> <b>Total Points</b> </label>
                      <input type="number" class="form-control" max="4" value="{{getTotalPoints()}}" formControlName="totalPoints"/>
                    </div>
                  </div>
                  <div class="col-md-3 mt-4">
                    <div>
                      <button type="submit" class="btn btn-success mt-2" (click)="saveDetails()" [disabled]="is_form_disabled">Save details
                        <i class="bi bi-arrow-right"></i>
                      </button>
                    </div>
                  </div>
                </div> -->
              <h2 class="text-center">Calculator</h2>
              <h3 class="mt-10 mt-5">
                Calculation matrix
              </h3>
              <p>Please calculate the total team efforts</p>
              <ul>
                <li>On an average walking 1km = 1300 steps</li>
                <li>Individual Sport: tennis, badminton, boxing etc</li>
                <li>Team Sport: cricket, football, hockey, basketball, rugby etc</li>
              </ul>
              <div class="row">
                <div class="col-md-7">
                  <div class="row">
                    <div class="col-md-4 mt-5">
                      <label for="walking" class="form-label">Walking <i>(Steps)</i></label>
                      <input type="number" class="form-control" style="width: 70%;" placeholder="Steps" id="walking"
                        formControlName="walking" />

                    </div>
                    <div class="col-md-4 mt-5">
                      <label for="running" class="form-label">Running <i>(Steps)</i></label>
                      <input type="number" class="form-control" style="width: 70%;" placeholder="Steps" id="running"
                        formControlName="running" />

                    </div>
                    <div class="col-md-4 mt-5">
                      <label for="cycling" class="form-label">Cycling <i>(kms)</i></label>
                      <input type="number" class="form-control" style="width: 70%;" placeholder="Distance" id="cycling"
                        formControlName="cycling" />
                    </div>
                    <div class="col-md-4 mt-5">
                      <label for="yoga" class="form-label">Yoga <i> (time)</i></label>
                      <input type="number" class="form-control" style="width: 70%;" placeholder="Time (in min)"
                        id="yoga" formControlName="yoga" />
                    </div>
                    <div class="col-md-4 mt-5">
                      <label for="running" class="form-label">Swimming <i>(time)</i></label>
                      <input type="number" class="form-control" style="width: 70%;" placeholder="Time (in min)"
                        id="swimming" formControlName="swimming" />
                    </div>
                    <div class="col-md-4 mt-5">
                      <label for="gym" class="form-label">Gym <i>(time)</i></label>
                      <input type="number" class="form-control" style="width: 70%;" placeholder="Time (in min)" id="gym"
                        formControlName="gym" />
                    </div>
                    <div class="col-md-4 mt-5">
                      <label for="team_sport" class="form-label">Team Sport <i>(time)</i></label>
                      <input type="number" class="form-control" style="width: 70%;" placeholder="Time (in min)"
                        id="team_sport" formControlName="team_sport" />
                    </div>
                    <div class="col-md-4 mt-5">
                      <label for="individual_sport" class="form-label">Individual Sport <i>(time)</i></label>
                      <input type="number" class="form-control" style="width: 70%;" placeholder="Time (in min)"
                        id="individual_sport" formControlName="individual_sport" />
                    </div>
                  </div>
                </div>
                <div class="col-md-5">
                  <h2 class="mt-10">Total Points of the Team: {{getTotalPoints()}}</h2>
                  <div class="row">
                    <div class="col-md-12">
                      <table class="table">
                        <thead>
                          <tr>
                            <th scope="col">Walking</th>
                            <th scope="col">Running</th>
                            <th scope="col">Cycling</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td>{{getPoints('walking')}}</td>
                            <td>{{getPoints('running')}}</td>
                            <td>{{getPoints('cycling')}}</td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                    <div class="col-md-12">
                      <table class="table">
                        <thead>
                          <tr>
                            <th scope="col">Yoga</th>
                            <th scope="col">Swimming</th>
                            <th scope="col">Gym</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td>{{getPoints('yoga')}}</td>
                            <td>{{getPoints('swimming')}}</td>
                            <td>{{getPoints('gym')}}</td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                    <div class="col-md-12">
                      <table class="table">
                        <thead>
                          <tr>
                            <th scope="col">Team Sport</th>
                            <th scope="col">Individual Sport</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td>{{getPoints('team_sport')}}</td>
                            <td>{{getPoints('individual_sport')}}</td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>

              <div class="col-md-12 partition" id="logPoint">

              </div>
              <div class="row p-3">
                <h3 class="mt-10 mt-5 text-center">
                  Log the points
                </h3>
                <h2>{{currentWeek}}</h2>

                <div class="col-md-4">
                  <div *ngIf="errorText && errorText !== ''" class="error-msg mt-10">{{errorText}}</div>
                </div>
              </div>
              <div class="row">

                <div class="col-md-3">
                  <label for="teamName" class="form-label">Select Team</label>
                  <select formControlName="teamName" class="form-select" id="teamName" required
                    *ngIf="teamDetails.length > 0">
                    <option style="display: none;" selected>Please Select</option>
                    <!-- Uncomment below lines-->
                    <option *ngFor="let team of teamDetails" class="bi bi-chevron-down">
                      {{team['value']}}
                    </option>
                  </select>
                  <div class="invalid-feedback">
                    Please provide a valid team name.
                  </div>
                </div>
                <div class="col-md-2">
                  <div>
                    <label for="teamPin" class="form-label">Security Pin</label>
                    <input type="password" class="form-control" maxlength="4" placeholder="Enter 4 digit pin"
                      id="teamPin" formControlName="teamPin" />
                  </div>

                </div>
                <div class="col-md-2">
                  <div>
                    <label for="ttalPoints" class="form-label" style="color: #4154f1;"> <b>Total Points</b> </label>
                    <input type="number" class="form-control" max="4" value="{{getTotalPoints()}}"
                      formControlName="totalPoints" />
                  </div>
                  <!-- <h3 class="mt-10">Total Points of the Team: {{getTotalPoints()}}</h3> -->
                </div>
                <div class="col-md-3 mt-4">
                  <div>
                    <button type="submit" class="btn btn-success mt-2" (click)="saveDetails()"
                      [disabled]="is_form_disabled">Save details
                      <i class="bi bi-arrow-right"></i>
                    </button>
                  </div>
                </div>
              </div>

              <!-- <div class="text-center text-lg-start mt-5">

                  <h2 class="mt-10">Total Points of the Team: {{getTotalPoints()}}</h2>

                  <div class="row mt-4">
                    <table class="table">
                      <thead>
                        <tr>
                          <th scope="col">Walking</th>
                          <th scope="col">Running</th>
                          <th scope="col">Cycling</th>
                          <th scope="col">Yoga</th>
                          <th scope="col">Swimming</th>
                          <th scope="col">Gym</th>
                          <th scope="col">Team Sport</th>
                          <th scope="col">Individual Sport</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>{{getPoints('walking')}}</td>
                          <td>{{getPoints('running')}}</td>
                          <td>{{getPoints('cycling')}}</td>
                          <td>{{getPoints('yoga')}}</td>
                          <td>{{getPoints('swimming')}}</td>
                          <td>{{getPoints('gym')}}</td>
                          <td>{{getPoints('team_sport')}}</td>
                          <td>{{getPoints('individual_sport')}}</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>




                </div> -->
            </form>
          </div>
        </div>

        <!-- <div class="col-lg-6 d-flex align-items-center">
              <img src="../assets/img/about.jpg" class="img-fluid" alt="">
            </div> -->

      </div>
    </div>

  </section><!-- End About Section -->

  <!-- ======= Values Section ======= -->




  <!-- End Values Section -->
  <!-- ======= Counts Section ======= -->
  <section id="contact" class="counts">
    <div class="container">

      <div class="row gy-4">
        <p>Contact Us</p>

        <!-- <div class="col-lg-3 col-md-6">
            <div class="count-box">
              <i class="bi bi-emoji-smile"></i>
              <div>
                <span data-purecounter-start="0" data-purecounter-end="232" data-purecounter-duration="1" class="purecounter"></span>
                <p>Happy Clients</p>
              </div>
            </div>
          </div> -->

        <!-- <div class="col-lg-3 col-md-6">
            <div class="count-box">
              <i class="bi bi-journal-richtext" style="color: #ee6c20;"></i>
              <div>
                <span data-purecounter-start="0" data-purecounter-end="521" data-purecounter-duration="1" class="purecounter"></span>
                <p>Projects</p>
              </div>
            </div>
          </div> -->

        <!-- <div class="col-md-4">
            <div class="count-box">
              <i class="bi bi-headset" style="color: #15be56;"></i>
              <div>
                <span data-purecounter-start="0" data-purecounter-end="1463" data-purecounter-duration="1" class="purecounter"></span>
                <p>Arun Rajagopalan</p>
                <p>arun.rajagopalan@landg.com</p>
              </div>
            </div>
          </div> -->
        <div class="col-md-4">
          <div class="count-box">
            <i class="bi bi-headset" style="color: #15be56;"></i>
            <div>
              <span data-purecounter-start="0" data-purecounter-end="1463" data-purecounter-duration="1"
                class="purecounter"></span>
              <p>Shivansh Kashyap</p>
              <p><a href="mailto:shivansh.kashyap@landg.com">shivansh.kashyap&#64;landg.com</a></p>
            </div>
          </div>
        </div>
        <div class="col-md-4">
          <div class="count-box">
            <i class="bi bi-headset" style="color: #15be56;"></i>
            <div>
              <span data-purecounter-start="0" data-purecounter-end="1463" data-purecounter-duration="1"
                class="purecounter"></span>
              <p>Rajeshwar Nag</p>
              <p><a href="mailto:rajeshwar.nag@landg.com">rajeshwar.nag&#64;landg.com</a></p>
            </div>
          </div>
        </div>
        <div class="col-md-4"></div>


        <!-- <div class="col-lg-3 col-md-6">
            <div class="count-box">
              <i class="bi bi-people" style="color: #bb0852;"></i>
              <div>
                <span data-purecounter-start="0" data-purecounter-end="15" data-purecounter-duration="1" class="purecounter"></span>
                <p>Hard Workers</p>
              </div>
            </div>
          </div> -->

      </div>

    </div>
  </section>


  <!-- End Counts Section -->

</main>